<template>

    <div :class="slideBarClass">
        <div class="slideLogo" v-if="otherParams.showLogo">
            <img :src="otherParams.logoPng" style="height:30px;cursor: pointer;" @click="$router.push('/')"/>
            <h5 v-if="otherParams.showTitle">{{otherParams.systemTitle}}</h5>
        </div>
        <el-menu
                :collapse="isCollapse"
                :default-active="route.name"
                class="menuCss"
                background-color="var(--el-color-self-slide-bg)"
                text-color="#fff"
                active-text-color="#409eff"
                :collapse-transition="false"
        >


            <MenuItem :userMenus="userMenus" ref="menuItemRef"/>
        </el-menu>
    </div>
</template>

<script>
    import MenuItem from "@/components/layout/MenuItem";
    import {reactive, toRefs, nextTick, onMounted, computed, getCurrentInstance, defineComponent, inject,watch} from 'vue';
    import {useRoute} from "vue-router";
    import config from "@/utils/config";
    import main_logo from '@/assets/main_logo.png';
    export default defineComponent({
        name: "SlideBar",
        setup(){
            const {proxy} = getCurrentInstance();
            const utils=proxy.utils;
            const route = useRoute();
            let mainComp=inject('mainComp');
            const dataObj=reactive({
                menuItemRef:null,
                isCollapse: false,
                amountFlag:false,
                otherParams:{
                    systemTitle:config.title,
                    showLogo:!mainComp.otherParams.showTop,
                    logoPng: main_logo,
                    showTitle:true,
                }
            });
            watch(() => mainComp.otherParams.showTop,async (newValue,oldValue) => {
                dataObj.otherParams.showLogo=!newValue;
            });
            watch(() => dataObj.isCollapse,async (newValue,oldValue) => {
                dataObj.otherParams.showTitle=!newValue;
            });
            onMounted(()=>{
                dataObj.amountFlag=true;
                nextTick(()=>{
                    let _systemTitle=sessionStorage.getItem('systemTitle');
                    if(_systemTitle)dataObj.otherParams.systemTitle=_systemTitle;
                })
            });
            const userMenus=computed(()=>{
                if(dataObj.isCollapse || dataObj.amountFlag){
                    nextTick(()=>{
                        if(sessionStorage.getItem("slideUserMenus")){
                            let userMenus=JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("slideUserMenus")));
                            dataObj.menuItemRef.userMenus=userMenus;
                            return userMenus;
                        }else{
                            let userMenus=JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userMenus")));
                            dataObj.menuItemRef.userMenus=userMenus;
                            return userMenus;
                        }
                    })
                }else{
                    return [];
                }
            })
            const slideBarClass=computed(()=>{
                return {
                    slideBar_big: !dataObj.isCollapse,
                    slideBar_small: dataObj.isCollapse
                };
            })
            const changeMenu=(menus)=>{
                dataObj.menuItemRef.userMenus=menus;
            }
            return{
                ...toRefs(dataObj),userMenus,slideBarClass,changeMenu,route
            }
        },
        components: {
            MenuItem
        }
    });
</script>

<style scoped lang="scss">
    .slideBar_big {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .slideLogo{
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: var(--el-color-self-slide-bg);
        }
    }

    .slideBar_small {
        position: fixed;
        width: $slide-collapse;
        height: 100%;
        overflow-y: auto;
        .slideLogo{
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: var(--el-color-self-slide-bg);
        }
    }

    .menuCss {
        height: 100%;
        border-right: none !important;
    }
</style>