import { reactive,toRefs,onMounted,watch,getCurrentInstance,nextTick,defineComponent} from 'vue';
import ContentUtil,{IContentDataObj} from "@/components/layout/ts/content/contentUtil";
import {useRoute} from "vue-router";
export default defineComponent({
    name: "Content",
    setup(){
        const {proxy} = getCurrentInstance() as any;
        const route = useRoute()
        let dataObj:IContentDataObj=reactive<IContentDataObj>({
            refMap:new Map(),
            utilInst:{}as any,
            compRef:null,
            outerLink:'',
            isRouterAlive: true,
            animationName:!localStorage.getItem('routeAnimation')?'fade-transform':localStorage.getItem('routeAnimation')as string
        })

        onMounted(()=>{
            dataObj.utilInst=new ContentUtil(proxy,dataObj);
            nextTick(async ()=>{

                dataObj.utilInst.dealOuterLink(route.meta);
            })
        });
        watch(() => route.path,() => {
            dataObj.utilInst.dealOuterLink(route.meta);
        })

        const reload=()=> {
            dataObj.utilInst.reloadRoute();
        }
        return{
            ...toRefs(dataObj),reload,route
        }
    },
    components: {

    }
});