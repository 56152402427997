import {mapActions, useStore} from "vuex";
import {getCurrentInstance, onBeforeUpdate,onMounted, watch, defineComponent, inject} from "vue";
import {RouteLocationNormalizedLoaded, useRoute} from "vue-router";
let dropdownRefs: any[] = []
export default defineComponent({
    name: "Tabs",
    setup(){
        let mainComp=inject('mainComp') as any;
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const route = useRoute();
        let store = useStore();

        let visitedTabsView = utils.Tools.getPropFromVuex('visitedTabsView');

        let actions=mapActions(["addVisitedTabsView","closeSpeTabsView","closeAllVisitedTabsView","closeOtherTabsView"]);

        let addVisitedTabsView = actions.addVisitedTabsView.bind({ $store: store });
        let closeSpeTabsView = actions.closeSpeTabsView.bind({ $store: store });
        let closeAllVisitedTabsView = actions.closeAllVisitedTabsView.bind({ $store: store });
        let closeOtherTabsView = actions.closeOtherTabsView.bind({ $store: store });

        onBeforeUpdate(() => {
            dropdownRefs = []
        })
        onMounted(async ()=>{

        })
        const doActiveTopMenu=()=>{
            if(mainComp.topRef)mainComp.topRef.doActiveTopMenu()
        }

        watch(() => route.path,async () => {
                if('/lockScreen'!=route.fullPath)await addVisitedTabsView(route);
                doActiveTopMenu();
            }
        )
        const isActive=(curRoute:any)=>{
            return curRoute.path === route.path || curRoute.title === route.meta.title;
        }
        const closeHandler=async (tag:RouteLocationNormalizedLoaded)=>{
            if(tag.path==='/welcome')return;
            let visitedTabsView= await closeSpeTabsView(tag);

            if (isActive(tag)) {
                const lastTag = visitedTabsView.slice(-1)[0];
                lastTag?proxy.$router.replace(lastTag.path):proxy.$router.replace("/");
            }
        }

        const refreshHandler=(tag:RouteLocationNormalizedLoaded)=>{
            if (tag.path == proxy.$router.currentRoute.value.path){
                mainComp.contentRef.reload()
            } else {
                proxy.$router.replace(tag.path);
            }
        }

        const dropCommand=async (event:any, path:string, index:number)=>{
            let route;
            switch (event) {
                case 'closeCurrent':
                    route=visitedTabsView.find((item:any)=>item.path==path)
                    await closeHandler(route);
                    break;
                case 'closeOthers':
                    route=visitedTabsView.find((item:any)=>item.path==path)
                    await closeOtherTabsView(route);
                    proxy.$router.replace(route.path);
                    break;
                case 'closeAll':
                    await closeAllVisitedTabsView();
                    await proxy.$router.push("/welcome");
                    break;
                case 'closeLeft':
                    const lIdx = dropdownRefs.findIndex((item: any) => item.id === path);
                    let delIds:Array<string>=[];

                    for(let i=0;i<dropdownRefs.length;i++){
                        if(i<lIdx){
                            route=visitedTabsView.find((item:any)=>item.path==dropdownRefs[i]['id']);
                            if(route && route.path!='/welcome'){
                                await closeHandler(route);
                                delIds.push(dropdownRefs[i]['id'])
                            }
                        }
                    }
                    dropdownRefs=dropdownRefs.filter((item:any)=>delIds.indexOf(item.id)===-1);
                    break;
                case 'closeRight':
                    const rIdx = dropdownRefs.findIndex((item: any) => item.id === path);
                    let _delIds:Array<string>=[];
                    for(let i=0;i<dropdownRefs.length;i++){
                        if(i>rIdx){
                            route=visitedTabsView.find((item:any)=>item.path==dropdownRefs[i]['id'])
                            if(route){
                                await closeHandler(route);
                                _delIds.push(dropdownRefs[i]['id'])
                            }
                        }
                    }
                    dropdownRefs=dropdownRefs.filter((item:any)=>_delIds.indexOf(item.id)===-1);
                    break;
            }
        }

        const dropVisibleChange = (v: boolean, path: string) => {
            if (v) {
                dropdownRefs.forEach((item:any, i:number) => {
                    if (item.id != path)item.handleClose()
                })
            }
        }

        const setDropdownRef = (el: any) => {
            if (el) {
                let exist=dropdownRefs.some((item:any)=>item.id==el.id);
                if(!exist)dropdownRefs.push(el)
            }
        }
        return{
            visitedTabsView,isActive,closeHandler,refreshHandler,doActiveTopMenu,dropCommand, dropVisibleChange,setDropdownRef
        }
    },
});