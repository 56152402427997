import BaseBean from "@/utils/BaseBean";
import {nextTick} from "vue";
import Theme from "@/mythemes/theme";
export interface IPageAnimation{
    selItems:Array<string>
    items:Array<TIdValue>
}
export interface IRouteAnimation {
    defRouteAnimation:string
    options:Array<TValueLabel>
}
export interface IDialogAnimation {
    isActiveBg:boolean
    backPicPath:string
    imageSource:Array<ResourceFileBean>
    isActive:boolean
}
export interface IAccessedModule{
    isVisible:boolean
    moduleArr:Array<any>
}
export interface ITopDataObj {
    utilInst: TopUtil
    refMap:Map<string,any>
    systemTitle:string
    searchContent:string,
    isSearch:boolean,
    userInfo:IStoreUserInfo
    userMenus:any
    hasMessageReceivePermission:boolean
    hasChangePersonalInfosPermission:boolean
    isTopMenu:boolean
    activeTopMenu: string
    topMenu: any
    notQsNum: string
    pageAnimation:IPageAnimation
    routeAnimation:IRouteAnimation
    dialogAnimation:IDialogAnimation
    themes: any
    avatarImage: string
    logoPng:string
    accessedModule:IAccessedModule
    flag1:boolean
    otherParams:any
}
export const defColor={
    topDefColor:'#242f42',
    slideDefColor:'#324157'
}
export default class TopUtil extends BaseBean{
    public dataObj:ITopDataObj;
    constructor(proxy:any,dataObj:ITopDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    public initTopMenu(mainComp:any):void{
        this.dataObj.isTopMenu = (localStorage.getItem('isTopMenu')=='true'?true:false);
        if (this.dataObj.isTopMenu)this.dataObj.utilInst.buildTopMenu(mainComp);
    }
    public initPageAnimationConfig():void{
        this.dataObj.pageAnimation.items.forEach((item:any)=>{
            if(this.utils.Tools.isAnimationOpen(item.id))this.dataObj.pageAnimation.selItems.push(item.id);
        })
    }
    public dialogBgConfig():void{
        nextTick(()=>{
            //从sessionStorage取出图片集合
            let imageSourceStr:string=sessionStorage.getItem("imageSource") as string;
            let imageSource:Array<ResourceFileBean>= JSON.parse(this.utils.UtilPub.decrypt(imageSourceStr)) as Array<ResourceFileBean>;
            if(imageSource.length>0){
                this.dataObj.dialogAnimation.imageSource=imageSource;
                let dynamicImage:ResourceFileBean={id: "", saveType: 0, type: 0, uploadTime: "", userId: "", preName:'随机图片',nowName: "",path:'_dynamic_'}
                this.dataObj.dialogAnimation.imageSource.splice(0, 0, dynamicImage);
            }
        })
    }
    public async queryNotQsMsgNum():Promise<void>{
        nextTick(async ()=>{
            let res = await this.utils.Api.queryNotQsMsgNum();
            if (res.notQsNum == 0) this.dataObj.notQsNum = '';
            else this.dataObj.notQsNum = res.notQsNum;
        })
    }
    public topMenuClickHandler(key:any, keyPath:Array<any>,that:TopUtil,mainComp:any):void{
        let menu = that.dataObj.userMenus.find((item:any)=> {
            return item.id == key
        });
        if (menu.children && mainComp.slideBarRef)mainComp.slideBarRef.changeMenu(menu.children);
    }
    public searchHandler():void{
        this.dataObj.isSearch=true;
        nextTick(async () => {
            this.dataObj.refMap.get('searchKeyRef').focus();
        });
    }
    public searchBlurHandler():void{
        if(this.dataObj.searchContent){
            setTimeout(()=>{
                if(this.dataObj.searchContent){
                    this.dataObj.isSearch=false;
                    this.dataObj.searchContent="";
                }
            },300);
        }else{
            this.dataObj.isSearch=false;
        }
    }
    public querySearchHandler(queryString:string, cb:Function,that:TopUtil):void{
        let queryResult = queryString ? that.filterMenuItem(that.dataObj.userMenus,queryString,undefined) : that.dataObj.userMenus;
        let filterResult:Array<any>=[];
        queryResult.forEach((item:any)=> {
            filterResult.push({value: item.title, path: item.path})
        });
        cb(filterResult);
    }
    public filterMenuItem(userMenus:any,queryString:string,parentTitle:string|undefined):Array<any>{
        let filterResult:Array<any>=[];
        userMenus.forEach((item:any)=> {
            if(item.children){
                let preTitle=parentTitle?(parentTitle+item.meta.title+" > "):item.meta.title+" > ";
                let tempResult=this.filterMenuItem(item.children,queryString,preTitle);
                if(tempResult.length>0) filterResult=filterResult.concat(tempResult);
            }else{
                if(item.meta.title.toLowerCase().indexOf(queryString.toLowerCase())>-1){
                    let title=parentTitle?(parentTitle+item.meta.title):item.meta.title;
                    filterResult.push({path: item.path, title:title})
                }
            }
        });
        return filterResult;
    }

    public selectMenuHandler(item:any,that:TopUtil):void{
        if(item.path){
            that.proxy.$router.push({path: item.path});
        }
    }

    public buildTopMenu(mainComp:any):void{
        this.dataObj.topMenu = [];
        this.dataObj.userMenus.forEach((item:any)=> {
            this.dataObj.topMenu.push({
                id: item.id,
                children: item.children,
                path: item.path,
                page: item.page,
                meta:item.meta
            })
        });
        if (this.dataObj.topMenu.length > 0) {
            this.dataObj.activeTopMenu = this.dataObj.topMenu[0].id;
            if (this.dataObj.topMenu[0].children && mainComp.slideBarRef){
                mainComp.slideBarRef.changeMenu(this.dataObj.topMenu[0].children);
            }else if(mainComp.slideBarRef){
                mainComp.slideBarRef.changeMenu([]);
            }
        }
    }
    public async personalCommandHandler(command:string,that:TopUtil,mainComp:any):Promise<void>{
        switch (command) {
            case "live2dConfig":
                mainComp.otherParams.live2d.configVisible=true;
                break;
            case "rightSideBar":
                mainComp.otherParams.showTop=false;
                localStorage.setItem('showTop','false');
                this.utils.Const.topHeight=0;
                break;
            case "menuSwitch":
                that.utils.Tools.configBox({
                    message: that.proxy.$t('top.menuSwitchTip'),
                    sureFn: async () => {
                        if (that.dataObj.isTopMenu && mainComp.slideBarRef) {
                            mainComp.slideBarRef.changeMenu(that.dataObj.userMenus);
                        } else {
                            that.buildTopMenu(mainComp);
                        }
                        that.dataObj.isTopMenu = !that.dataObj.isTopMenu;
                        localStorage.setItem('isTopMenu',that.dataObj.isTopMenu+'');
                    }
                });
                break;
            case "animationConfig":
                that.dataObj.otherParams.isVisible=true;
                break;
            case "changePersonalInfos":
                await that.proxy.$router.replace({path: "/changePersonalInfos"});
                break;
            case "logOut":
                mainComp.logOut();
                break;
        }
    }


    public pageAnimationCkChange(selItems:Array<string>,that:TopUtil):void{
        that.dataObj.pageAnimation.selItems=selItems;
        that.dataObj.pageAnimation.items.forEach((item:TIdValue)=>{
            if (selItems.includes(item.id)) {
                localStorage.setItem(item.id, 'true');
            }else{
                localStorage.setItem(item.id, 'false');
            }
        });
    }
    public routeAnimationSelChange(selItem:string):void{
        localStorage.setItem('routeAnimation', selItem);
    }
    public themeSelChange(selItem:string):void{
        localStorage.setItem('curTheme', selItem);
        Theme.switchTheme(selItem);
    }
    public dialogBgIsOpen(val:boolean):void{
        localStorage.setItem('dialogBgAnimation',val.toString());
    }
    public dialogAnimationIsOpen(val:boolean):void{
        localStorage.setItem('dialogAnimation',val.toString());
    }
    public dialogBgPicConfigChange(selItem:string,that:TopUtil):void{
        selItem=that.utils.UtilPub.encrypt(selItem)
        localStorage.setItem('backPicPath', selItem);
    }
    public switchModuleHandler():void{
        this.dataObj.accessedModule.isVisible=true;
    }
}