import Top from "@/components/layout/Top.vue";
import SlideBar from "@/components/layout/SlideBar.vue";
import ContentArea from "@/components/layout/ContentArea.vue";
import Tabs from "@/components/layout/Tabs.vue";
import MainUtil,{IMainDataObj} from "@/components/layout/ts/main/mainUtil";
import {useStore} from "vuex";
import {ref, reactive, toRefs, onMounted,onBeforeMount, onActivated, computed, watch, getCurrentInstance, defineComponent, nextTick, provide} from 'vue';
import config from "@/utils/config";
import {useRoute} from "vue-router";
import Theme from "@/mythemes/theme";
import screenfull from "screenfull";
export default defineComponent({
    name: "Main",
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            if(!from.name && '/welcome'!=to.path){
                sessionStorage.setItem('refreshRouteInfo',JSON.stringify({title:to.meta.title,path:to.path}));
            }
        })
    },
    setup(){
        const topRef=ref(null) as any;
        const slideBarRef=ref(null) as any;
        const mainContentRef=ref(null) as any;
        const contentRef=ref(null) as any;
        const appInstant = getCurrentInstance();
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('mainComp', proxy);
        let store = useStore();
        let route = useRoute();
        const dataObj:IMainDataObj=reactive<IMainDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            contentClassFlag: false,
            isEffectOver: !(utils.Tools.isAnimationOpen('mainAnimation') && config.flag1),
            bookEffectParams:{
                inst:proxy
            },
            prop:0,
            showLayer: sessionStorage.getItem("showLayer"),
            otherParams:{
                flag1:config.flag1,
                live2d:{
                    configVisible:false,
                    closeType:'2',
                    currentLive2d:'shizuku',
                    data:[
                        {value:'shizuku',label:'萌娘',folderName:'shizuku'},{value:'chitose',label:'小帅哥',folderName:'chitose'},
                        {value:'Gantzert_Felixander',label:'剑客',folderName:'gf'},{value:'Epsilon2.1',label:'美女1',folderName:'epsilon2_1'},
                        {value:'haru01',label:'美女2',folderName:'haru_1'},{value:'haru02',label:'美女3',folderName:'haru_2'},
                        {value:'izumi',label:'美女4',folderName:'izumi'},{value:'hibiki',label:'女学生',folderName:'hibiki'},
                        {value:'koharu',label:'小可爱（女）',folderName:'koharu'}, {value:'haruto',label:'小可爱（男）',folderName:'haruto'},
                        {value:'miku',label:'初音',folderName:'miku'},{value:'nico',label:'小阿狸',folderName:'nico'},
                        {value:'ni-j',label:'动漫1',folderName:'ni-j'},{value:'nietzche',label:'动漫2',folderName:'nietzsche'},
                        {value:'nipsilon',label:'动漫3',folderName:'nipsilon'},{value:'nito',label:'动漫4',folderName:'nito'},
                        {value:'tsumiki',label:'绿毛妹妹',folderName:'tsumiki'},{value:'unitychan',label:'金龟子妹妹',folderName:'unitychan'},
                        {value:'z16',label:'圣职者妹妹',folderName:'z16'},{value:'tororo',label:'白猫',folderName:'tororo'},
                        {value:'hijiki',label:'黑猫',folderName:'hijiki'},{value:'wanko',label:'茶杯犬',folderName:'wanko'},
                    ],
                },
                menuIcon:'iconfont icon-a-cebianlanfenleizhedie',//折叠/展开图标
                showTop:localStorage.getItem('showTop')?(localStorage.getItem('showTop')=='false'?false:true):true,//是否展示顶部(默认展示)
                splitParams:{
                    lwidth:'13%',
                    lMinWidth:54,
                }
            }
        })
        onActivated( async()=>{
            dataObj.prop=route.meta.prop;
            await dataObj.utilInst.recoverLockScreenState();
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new MainUtil(proxy,appInstant,dataObj);

            let curTheme=!localStorage.getItem('curTheme')?'defaultTheme':localStorage.getItem('curTheme') as string;
            Theme.switchTheme(curTheme);
        })

        onMounted(async ()=>{
            await nextTick(async () => {
                await dataObj.utilInst.reCreateWebSocket();
                let refreshRouteInfo:string=sessionStorage.getItem('refreshRouteInfo')as string;
                if(refreshRouteInfo){
                    sessionStorage.removeItem('refreshRouteInfo');
                    await proxy.$router.push({path:JSON.parse(refreshRouteInfo).path});
                    await store.dispatch("recoverViews", [JSON.parse(refreshRouteInfo)]);
                }else{
                    await proxy.$router.push({path: "/welcome"});
                    await store.dispatch("recoverViews",[]);
                }

            });
        })
        //---------------------------computed------------
        const slideClass=computed(()=>{
            return {
                slide_open: !dataObj.contentClassFlag,
                slide_collapse: dataObj.contentClassFlag
            }
        })
        const effectOver=()=>{
            dataObj.isEffectOver=true;
        }

        const fullScreen=async ()=>{
            if (!screenfull.isEnabled) utils.Tools.info({message: proxy.$t('fullScreenMsg')});
            else await screenfull.toggle();
        }
        const backMenu=async ()=>{

            if(slideBarRef && slideBarRef.value)slideBarRef.value.menuItemRef.userMenus=[];
            await proxy.$router.replace({path: "/layer"});
        }
        //退出登录
        const logOut=()=>{
            utils.Tools.configBox({
                message: proxy.$t('login.loginOutNote'),
                sureFn: async () => {
                    proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});

                    sessionStorage.removeItem("userInfo");
                    let res = await utils.Api.loginOut();
                    if(res.result){
                        utils.Tools.success({message: proxy.$t('login.loginOutMsg')});
                        location.reload();
                    }else{
                        utils.Tools.error();
                    }
                }
            });
        }
        const showTop=()=>{
            dataObj.otherParams.showTop=true;
            localStorage.setItem('showTop','true');
            utils.Const.topHeight=56;
        }
        const lockScreen=()=>{
            let lockScreenObject:ILockScreen={lockScreen:true,lockScreenTime:new Date(),lockScreenState:'locked',currentRoute:proxy.$router.currentRoute.value.path}
            sessionStorage.setItem("lockScreen", JSON.stringify(lockScreenObject));
            proxy.$router.push({path:'/lockScreen'});
        }
        const collapseLeft=()=>{
            if (dataObj.otherParams.menuIcon == "iconfont icon-a-cebianlanfenleizhedie") {
                dataObj.otherParams.menuIcon = "iconfont icon-a-fenleizhediecebianlan";
                if(slideBarRef && slideBarRef.value)slideBarRef.value.isCollapse = true;
                dataObj.contentClassFlag = true;
                if(dataObj.refMap.get('split'))dataObj.refMap.get('split').cfgWidth({lwidth:'59px'});
            } else {
                dataObj.otherParams.menuIcon = "iconfont icon-a-cebianlanfenleizhedie";
                if(slideBarRef && slideBarRef.value)slideBarRef.value.isCollapse = false;
                dataObj.contentClassFlag = false;
                if(dataObj.refMap.get('split'))dataObj.refMap.get('split').cfgWidth({lwidth:dataObj.otherParams.splitParams.lwidth});
            }
        }

        const configLive2d=()=>{
            dataObj.utilInst.configLive2d();
        }
        return{
            ...toRefs(dataObj),topRef,slideBarRef,mainContentRef,contentRef,slideClass,effectOver,
            fullScreen,backMenu,logOut,showTop,lockScreen,collapseLeft,configLive2d
        }
    },

    components: {
        Top,
        SlideBar,
        ContentArea,
        Tabs
    }
});