import BaseBean from "@/utils/BaseBean";
import {nextTick} from "vue";
import {useRoute} from "vue-router";

export interface IContentDataObj {
    refMap:Map<string,any>
    utilInst:any
    compRef:any
    outerLink:string
    isRouterAlive: boolean
    animationName:string
}
export default class ContentUtil extends BaseBean{
    public dataObj:IContentDataObj;
    constructor(proxy:any,dataObj:IContentDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }

    public dealOuterLink(meta:any):void{
        if(meta.page && meta.page.startsWith("http")){
            if(meta.type==1){
                const userToken = sessionStorage.getItem(this.utils.Const.jfAccessToken);
                let pageUrl=meta.page+'?token='+userToken;
                this.dataObj.outerLink=pageUrl;
            }else{
                this.dataObj.outerLink=meta.page as string;
            }
        }else{
            this.dataObj.outerLink='';
        }
    }
    public reloadRoute():void{
        this.dataObj.isRouterAlive = false;
        nextTick(()=>{
            this.dataObj.isRouterAlive = true;
        })
    }
}