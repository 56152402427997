import TopUtil,{IPageAnimation,IRouteAnimation,IDialogAnimation,ITopDataObj,IAccessedModule} from "@/components/layout/ts/top/topUtil";
import {reactive, toRefs, onMounted, onBeforeMount, getCurrentInstance, nextTick, defineComponent, onActivated,inject} from 'vue';
import config from "@/utils/config";
import user from '@/assets/user.jpg';
import main_logo from '@/assets/main_logo.png';
export default defineComponent({
    name: "Top",
    setup(){
        let mainComp=inject('mainComp') as any;
        const {proxy} = getCurrentInstance() as any;
        const utils=proxy.utils; 
        let pageAnimation:IPageAnimation={
            selItems:[],
            items:[
                {id:'loginAnimation',value:proxy.$t('top.loginAnimation')},
                {id:'mainAnimation',value:proxy.$t('top.mainAnimation')},
                {id:'listAnimation',value:proxy.$t('top.listAnimation')},
                {id:'formAnimation',value:proxy.$t('top.formAnimation')}
            ]
        }
        let routeAnimation:IRouteAnimation={
            defRouteAnimation:!localStorage.getItem('routeAnimation')?'fade-transform':localStorage.getItem('routeAnimation') as string,
            options:[
                {value:'el-zoom-in-center',label: 'el-zoom-in-center'},
                {value:'el-fade-in',label: 'el-fade-in'},
                {value:'fade-transform',label: 'fade-transform'}
            ]
        }
        let dialogAnimation:IDialogAnimation={
            isActiveBg:utils.Tools.isAnimationOpen('dialogBgAnimation'),
            backPicPath:!localStorage.getItem('backPicPath')?'_dynamic_':utils.UtilPub.decrypt(localStorage.getItem('backPicPath')),
            imageSource:[],
            isActive:utils.Tools.isAnimationOpen('dialogAnimation')
        }

        let accessedModule:IAccessedModule={
            isVisible:false,
            moduleArr:[]
        }
        const dataObj:ITopDataObj=reactive<ITopDataObj>({
            utilInst: {} as any,
            refMap:new Map(),
            systemTitle:config.title,
            searchContent:'',
            isSearch:false,
            userInfo:sessionStorage.getItem('userInfo')?JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem('userInfo'))):'',
            userMenus:sessionStorage.getItem('userMenus')?JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem('userMenus'))):'',

            hasMessageReceivePermission:utils.Tools.getPropFromVuex('hasMessageReceivePermission'),

            hasChangePersonalInfosPermission:utils.Tools.getPropFromVuex('hasChangePersonalInfosPermission'),
            isTopMenu: true,
            activeTopMenu: '',
            topMenu: [],
            notQsNum: '',
            pageAnimation: pageAnimation,
            routeAnimation:routeAnimation,
            dialogAnimation:dialogAnimation,
            themes: {
                curTheme:!localStorage.getItem('curTheme')?'defaultTheme':localStorage.getItem('curTheme') as string,
                themeArray:[{label:proxy.$t('top.defaultTheme'),value:'defaultTheme'},{label:proxy.$t('top.darkTheme'),value:'darkTheme'},{label:proxy.$t('top.lightBlueTheme'),value:'lightBlueTheme'}]
            },
            avatarImage: user,
            logoPng: main_logo,
            accessedModule:accessedModule,
            flag1:config.flag1,
            otherParams:{
                isVisible:false,
            }
        });
        onActivated(()=>{
            if(sessionStorage.getItem("slideUserMenus")){
                dataObj.userMenus = JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("slideUserMenus")));
            }else{
                dataObj.userMenus = JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("userMenus")));
            }
            nextTick(async ()=>{
                dataObj.utilInst.initTopMenu(mainComp);
            })
            if(sessionStorage.getItem("accessModule")){
                let accessModule:any= JSON.parse(utils.UtilPub.decrypt(sessionStorage.getItem("accessModule")));
                let index=dataObj.accessedModule.moduleArr.findIndex((item:any)=>{
                    return item.id==accessModule.id;
                })
                if(index==-1)dataObj.accessedModule.moduleArr.push(accessModule);
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TopUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
            nextTick(async ()=>{
                await dataObj.utilInst.queryNotQsMsgNum();
                dataObj.utilInst.initTopMenu(mainComp);
                dataObj.utilInst.initPageAnimationConfig();
                dataObj.utilInst.dialogBgConfig();
                if(sessionStorage.getItem('systemTitle'))dataObj.systemTitle=sessionStorage.getItem('systemTitle') as string;
            })
        })
        const findMenu=(userMenus:any,menuPath:string)=>{
            return userMenus.find((item:any)=> {
                if(item.children){
                    let result=findMenu(item.children,menuPath);
                    if(result) return result;
                }else{
                    if(item.path==menuPath)return item;
                }
            });
        }

        const doActiveTopMenu=(options:any)=>{
            if (dataObj.isTopMenu){
                let item=findMenu(dataObj.userMenus,proxy.$router.currentRoute.value.path);
                if(item)dataObj.activeTopMenu=item.id;
            }
        }
        const accessedModuleClick=(item:any)=>{
            if(item.children){
                dataObj.userMenus=item.children;
            }else{
                dataObj.userMenus=[item];
            }

            if(!item.children && item.meta.prop==2){
                mainComp.prop=2;
                proxy.$router.push(item.path);
            }else{
                mainComp.prop=0;
                nextTick(()=>{
                    mainComp.slideBarRef.changeMenu(dataObj.userMenus);
                })
            }
        } 
        return{
            ...toRefs(dataObj),mainComp,doActiveTopMenu,accessedModuleClick
        }
    }
})