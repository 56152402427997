
export default class ProjectTheme {
    constructor() {}
    public static allThemes():any{
        return{
            defaultTheme:{
                '--el-color-self-params-bg': '#ecf0f1',
                '--el-color-self-params-second-bg': 'white',
                '--el-color-self-brand-bg': '#ecf0f1',
                '--el-color-self-jrfagsx-bg': '#ecf0f1',
            },
            darkTheme:{
                '--el-color-self-params-bg': '#012447',
                '--el-color-self-params-second-bg': '#1B3651',
                '--el-color-self-brand-bg': '#1B3651',
                '--el-color-self-jrfagsx-bg': '#012447',
            },
            lightBlueTheme:{

            }
        }
    }
}