import BaseBean from "@/utils/BaseBean";
import {nextTick} from 'vue';
export interface IMainDataObj {
    utilInst:MainUtil
    refMap:Map<string,any>
    contentClassFlag: boolean
    isEffectOver: boolean
    bookEffectParams:any
    prop:any
    showLayer:string | null
    otherParams:any
}
export default class MainUtil extends BaseBean{
    public appInstant:any;
    public dataObj:IMainDataObj;

    constructor(proxy:any,appInstant:any,dataObj:IMainDataObj) {
        super(proxy);
        this.dataObj=dataObj;
        this.appInstant=appInstant;
    }
    public recoverLockScreenState():void{
        nextTick(async ()=>{
            let lockScreen:ILockScreen=JSON.parse(sessionStorage.getItem("lockScreen") as string);
            if(lockScreen!=null && lockScreen.lockScreenState=='locked'){
                lockScreen.lockScreenState='unLocked';
                sessionStorage.setItem("lockScreen",JSON.stringify(lockScreen));
                this.dataObj.isEffectOver=true;
                await this.proxy.$router.replace({path: lockScreen.currentRoute as string});
            }
        })
    }
    public async reCreateWebSocket():Promise<void>{
        if(!this.proxy.$socket){
            let usercode=(JSON.parse(this.utils.UtilPub.decrypt(sessionStorage.getItem("userInfo") as string)) as IStoreUserInfo).usercode;
            await this.utils.Websocket.createWebSocketCon(usercode,this.appInstant);
            await this.utils.Websocket.initWebSocket(this.appInstant);
        }
    }




    public configLive2d():void{
        this.dataObj.otherParams.live2d.configVisible=false
    }
}